import { TabOverview } from '../GIVeconomyPages/GIVeconomy';

function GIVeconomyView() {
	return (
		<>
			<TabOverview />
		</>
	);
}

export default GIVeconomyView;
